import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import * as theme from '../../theme'

import Typography from '../Typography/Typography'
import Button from '../Button/Button'
import jamLogo from '../../../static/img/jam-logo.png'
import { logout } from '../../../actions/utils/authUtils'

const ComponentRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.background2 };
  padding: 20px;
  text-align: center;

  @media (min-width: 1024px) {
    padding-top: 0;
  }

  & > * {
    max-width: 400px;
    margin-bottom: 14px;
  }
  & > *:last-child { margin-top: 30px; }
`

const LargeLogo = styled.img`
  width: 246px;
`

class GenericErrorDisplay extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: props.alwaysHasError || false,
      errorMessage: props.errorMessage
    }
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      errorMessage: error.message
    })
  }

  handleLogout = () => {
    logout()
  }

  render() {
    if (this.state.hasError) {
      let body, title
      switch(this.state.errorMessage) {
        case 'QVEST_EXPIRED':
          title = 'The Qvest has expired'
          body = 'It is no longer publicly available. Please contact the Qvest owner, if you wish to gain access to the results.'
          break
        case 'EMAIL_NOT_VERIFIED':
          title = 'E-mail address not verified'
          body = 'Please check your inbox and verify your account by clicking the link in your verification e-mail'
          break
        case 'EMAIL_NOT_WHITELISTED':
          title = 'E-mail address not on whitelist'
          body = 'This application may only be accessed by approved testers. Contact us to get approved, if you are a tester. If you meant to access our production application go to qvest.io'
          break
        default:
          title = 'Something went wrong :('
          body = 'We have made a note of the issue and will look into it. Please try again later.'
      }

      return (
        <ThemeProvider theme={theme.ocean}>
          <ComponentRoot>
            <LargeLogo src={jamLogo} />
            <div>
              <Typography variant="heading2">{title}</Typography>
            </div>
            <div>
              <Typography>{body}</Typography>
            </div>
            <Button onClick={this.handleLogout}>
              Log out
            </Button>
          </ComponentRoot>
        </ThemeProvider>
      )
    } else {
      return this.props.children
    }
  }
}

export default GenericErrorDisplay
