import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { gql,  useQuery } from '@apollo/client'

import Typography from '../../../components/common/Typography/Typography.js'
import JamHeader from '../../../components/qvest/JamHeader/JamHeader.js'
import OrderQuestionDataForm from '../../../components/qvest/OrderQuestionDataForm/OrderQuestionDataForm'
import { useOrderQuestionDump } from '../../../operations/question'


const ComponentRoot = styled.div`
  padding: 80px 20px;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.two[0]};
`

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`

const HorizontalRule = styled.hr`
  margin: 40px 0 20px 0;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.one[3]};
`

const FINALE_PAGE_QUERY = gql`
  query q($qvestId:String!) {
    qvest(id: $qvestId) {
      qvestId
      name
      language
      topic
      state
      schedule {
        inviteAt,
        openAt,
        closeAt
      }
    }
  }
`


const OrderCompletedMessage = ({ show }) => {
  if (!show) return null
  return (
    <Typography>
      <p>
        <FormattedMessage id="qvest.FinalePage.orderData.completed1" defaultMessage="Got it. Please check your inbox." />
      </p>
      <p>
        <FormattedMessage id="qvest.FinalePage.orderData.completed2" defaultMessage="If you cannot find an email from Question Jam there, please check your “junk” or “spam” folder." />
      </p>
    </Typography>
  )
}

const OrderDataSection = ({ qvestId }) => {
  const [isCompleted, setIsCompleted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const orderQuestionDump = useOrderQuestionDump()

  const handleSubmit = async (email) => {
    setError(false)
    setIsLoading(true)
    try {
      await orderQuestionDump(qvestId, email)
      setIsCompleted(true)
    } catch (ex) {
      setError(ex.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <HorizontalRule />
      <OrderCompletedMessage show={isCompleted} />
      <OrderQuestionDataForm loading={isLoading} show={!isCompleted} error={error} onSubmit={handleSubmit} />
    </div>
  )
}

const FinalePage = ({ match }) => {
  const qvestId = match.params.qvestId
  const { data } = useQuery(FINALE_PAGE_QUERY, { variables: { qvestId } })

  let topic, message
  if (data) {
    topic = data.qvest.topic
    message = (
      <Typography.p>
        <FormattedMessage
          defaultMessage="That's it! Our jam is done. See the output on the main screen."
          id="qvest.FinalePage.closure"
        />
      </Typography.p>
    )
  }

  return (
    <ComponentRoot>
      <JamHeader topic={topic} />
      <Content>
        {message}
        <OrderDataSection qvestId={qvestId} />
      </Content>
    </ComponentRoot>
  )
}

export default FinalePage
