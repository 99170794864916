import React, { useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import styled, { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'

import * as authUtils from '../../actions/utils/authUtils'
import * as theme from '../../components/theme'
import JamSignupCard from '../../components/auth/JamSignupCard/JamSignupCard'
import { loadLocale } from '../../utils/i18nUtils'
import Heading from '../../components/common/Typography/Heading'
import jamMetaImagePng from '../../static/img/jam-meta-image2.png'

const PAGE_TITLE = 'Question Jam'
const META_DESCRIPTION = 'Join a Question Jam here. No login required. Question Jam is a free meeting game for facilitators who want to add energy and engagement to any meeting or workshop.'

const ComponentRoot = styled.div`
  padding: 20px;
  /* 100vw breaks on screens below 320px because of device zoom */
  width: 100%;
  min-height: 100vh;
  /* media query to tackle IE bug of flexbox*/
  @media (min-width: 460px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  background-color: ${({ theme }) => theme.background2};

  & h1 {
    margin-bottom: 15px;
  }
`

const JamSignupPage = () =>  {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [wasSent, setWasSent] = useState(false)

  const locale = 'en'

  const handleSignup = async (credentials, organizationName) => {
    setIsLoading(true)
    setError(null)
    const intlOptions = Intl.DateTimeFormat().resolvedOptions()
    const fields = {
      source: 'jam',
      planId: 'jam',
      locale: intlOptions.locale || navigator.language,
      timeZone: intlOptions.timeZone, // timeZone field is not supported in IE
      organizationName
    }
    let error
    try {
      await authUtils.createUser(credentials, null, fields)
    } catch (e) {
      if (e instanceof Array && e.length > 0) {
        error = e[0]
      } else {
        error = e
      }
      setError(error)
    } finally {
      if (!error) setWasSent(true)
      setIsLoading(false)
    }
  }

  return (
    <IntlProvider locale={locale} messages={loadLocale(locale)}>
      <ThemeProvider theme={theme.ocean}>
        <ComponentRoot>
          <Helmet>
            <title>{PAGE_TITLE}</title>
            <meta property="description" content={META_DESCRIPTION} />
            <meta property="og:title" content={PAGE_TITLE} />
            <meta property="og:description" content={META_DESCRIPTION} />
            <meta property="og:image" content={jamMetaImagePng} />
            <meta property="og:image:secure_url" content={jamMetaImagePng} />
          </Helmet>
          <Heading.h1 variant="heading1">
            <FormattedMessage id="JamCreatePage.heading" defaultMessage="Question Jam" />
            {' '}
            <Heading variant="heading2" transparent>[BETA]</Heading>
          </Heading.h1>
          <JamSignupCard
            showEmail
            showFullName
            showOrganizationName
            showPassword
            showConsentBoxes
            isLoading={isLoading}
            error={error}
            wasSent={wasSent}
            onSignup={handleSignup}
          />
        </ComponentRoot>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default JamSignupPage
