import Keycloak from 'keycloak-js'
import * as api from '../apiClient'

const HOST_NAME = process.env.HOST_NAME

const OPTIONS = {
  url: `${HOST_NAME}/auth`,
  clientId: 'qvest-web-app',
  realm: 'qvest-app',
  resource: 'qvest-web-app',
  'auth-server-url': `${HOST_NAME}/auth/`,
  'ssl-required': 'external',
  'public-client': true,
  'confidential-port': 0
}

let keycloak

export function logout() {
  keycloak.logout({ returnTo: `${HOST_NAME}/jam` })
}

export async function getAuthHeader() {
  const minValidity = 30 // seconds of validity left
  await keycloak.updateToken(minValidity)
  return { Authorization: `Bearer ${keycloak.token}` }
}

export async function createUser(credentials, inviteToken = null, fields = {}) {
  const options = {
    method: 'POST',
    body: {
      query: `
          mutation m($input:UserCreateInput!) {
            createUser(input:$input) {
              subscribeLink
            }
          }
        `,
      variables: {
        input: { credentials, inviteToken, ...fields }
      }
    }
  }
  const data = await api.invoke('/api/graphql/public', options, false)
  return data.createUser
}

export async function initialize() {
  keycloak = new Keycloak(OPTIONS)
  await keycloak.init({ onLoad: 'login-required' })
  return true
}
