import React, { Fragment, useEffect, useState } from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import styled from 'styled-components'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import { Helmet } from 'react-helmet'

import Card from '../../../../components/common/Card/Card'
import Icon from '../../../../components/common/Icon/Icon'
import Button from '../../../../components/common/Button/Button'
import NextBar from '../../../../components/owner/NextBar/NextBar'
import Typography from '../../../../components/common/Typography/Typography'
import CenteredLoader from '../../../../components/common/CenteredLoader/CenteredLoader'
import LastUpdateStatus from '../../../../components/owner/LastUpdateStatus/LastUpdateStatus'
import OwnerParticipantTable from '../../../../components/owner/OwnerParticipantTable/OwnerParticipantTable'
import { ParticipantsHeading } from '../../../../components/owner/OwnerCardHeadings/OwnerParticipantsCardHeadings'
import { filterQvestsById } from '../../../../reducers/qvests'
import { filterParticipantsByQvest, filterValidityByQvest } from '../../../../reducers/participants'
import { filterGroupingsByQvest } from '../../../../reducers/grouping'
import { getGroupingByQvestId, } from '../../../../actions/groupingActions'
import {
  getParticipants,
  updateParticipant,
  createParticipant,
  toggleParticipantSuspension,
  downloadParticipantsFile,
} from '../../../../actions/participantActions'
import { hasMailFailed, hasReplied } from '../../../../utils/participantUtils'
import { isDraftQvestState, isDoneQvestState } from '../../../../utils/qvestUtils'
import { useModals } from './participants/OwnerQvestParticipantsModals'

const messages = defineMessages({
  INVALID_EMAIL: { id: 'owner.QvestParticipantsTab.invalidEmail', defaultMessage: 'Please enter a valid email address' },
  INVALID_NAME: { id: 'owner.QvestParticipantsTab.invalidName', defaultMessage: 'Please enter a valid name' },
  DUPLICATE_EMAILS: { id: 'owner.QvestParticipantsTab.duplicateEmails', defaultMessage: 'Two or more participants have the same e-mail address. Delete any additional participants.' },
  INVALID_FORMAT_PARTICIPANT_EMAILS: { id: 'owner.QvestParticipantsTab.invalidFormatEmails', defaultMessage: 'One or more participants have invalid e-mail addresses. Edit the relevant participants.' },
  GET_PARTICIPANTS_FAILURE: { id: 'owner.QvestParticipantsTab.loadFailed', defaultMessage: 'Failed to load participants, check connection and restart' },
  SAVE_FAILED: { id: 'owner.QvestParticipantsTab.createFailed', defaultMessage: 'Save failed for unknown reasons. The Qvest.io team will be notified about the issue. Please try again later or contact us if the issue continues.' },
  NEW_DUPLICATE_EMAIL: { id: 'owner.QvestParticipantsTab.newDuplicateEmail', defaultMessage: 'A participant with the given e-mail already exists. Two participant may not have the same e-mail address.' },
  MAIL_DELIVERY_FAILED: { id: 'owner.QvestParticipantsTab.mailDeliveryFailed', defaultMessage: 'One or more participants could not receive their mail. See e-mail addresses with exclamation marks below for further details.' },
  LEARN_MORE: { id: 'owner.QvestParticipantsTab.learnMoreLink', defaultMessage: 'Learn more' },
  title: { id: 'owner.OwnerQvestParticipantsTab.title', defaultMessage: 'Participants | {qvestName}' },
})

const MainLayout = styled.div`
  padding: 20px;
  min-width: 470px;
  & > *:not(:first-child) { margin-top: 20px; }
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > :not(:last-child) {
    display: inline-block;
    margin-right: 14px;
  }
`

const ErrorMessage = injectIntl(({ validity, participants, error, intl }) => {
  const hasError = (validity, errorName) => (validity && validity.items.some(i => i.name === errorName))
  if (error) {
    if (error.message === 'DUPLICATE_PARTICIPANT_EMAILS') {
      error = intl.formatMessage(messages['NEW_DUPLICATE_EMAIL'])
    }
  }
  if (participants && participants.some(p => !p.isSuspended && hasMailFailed(p))) {
    error = (
      <Fragment>
        {intl.formatMessage(messages['MAIL_DELIVERY_FAILED'])}
      </Fragment>
    )
  }
  if (!error && hasError(validity, 'DUPLICATE_PARTICIPANT_EMAILS')) {
    error = intl.formatMessage(messages['DUPLICATE_EMAILS'])
  }
  if (!error && hasError(validity, 'INVALID_FORMAT_PARTICIPANT_EMAILS')) {
    error = intl.formatMessage(messages['INVALID_FORMAT_PARTICIPANT_EMAILS'])
  }
  if (!error) {
    return null
  }
  return (
    <Card.Message error>
      <Typography cta tertiary variant="medium">{error}</Typography>
    </Card.Message>
  )
})

const ParticipantNextBar = ({ show, qvestId }) => {
  if (!show) return null
  return (
    <NextBar>
      {/* FIXME: whitelist non styled component props */}
      <Button.Link secondary to={`/owner/qvest/${qvestId}/groups`}>
        <FormattedMessage id="owner.QvestParticipantsTab.nextButton" defaultMessage="Next: Groups" />
        <Icon cta tertiary variant="angle-right" />
      </Button.Link>
    </NextBar>
  )
}

const ActionsBar = props => {
  const {
    isDraft,
    isDone,
    showCreate,
    hasGrouping,
    participants,
    onShowCreate,
    onRemoveAll,
    onShowImport,
    onDownload
  } = props

  // NOTE: Grouping needs to be loaded before participants can be added to a started Qvest
  let editButton = null
  if (isDraft || (!isDraft && hasGrouping)) {
    if (showCreate) {
      editButton = (
        <Button>
          <FormattedMessage id="owner.QvestParticipantsTab.cancel" defaultMessage="Cancel" />
        </Button>
      )
    } else {
      editButton = (
        <Button disabled={isDone} onClick={onShowCreate}>
          <FormattedMessage id="owner.QvestParticipantsTab.addParticipant" defaultMessage="Add participant" />
        </Button>
      )
    }
  }

  const hasNoParticipants = (!participants || participants.length === 0)
  return (
    <Actions>
      <ButtonGroup>
        <Button outline disabled={!isDraft || hasNoParticipants} onClick={onRemoveAll}>
          <FormattedMessage id="owner.QvestParticipantsTab.deleteAll" defaultMessage="Clear" />
        </Button>
        <Button outline disabled={hasNoParticipants} onClick={onDownload}>
          <FormattedMessage id="owner.QvestParticipantsTab.download" defaultMessage="Download" />
        </Button>
      </ButtonGroup>
      <ButtonGroup>
        <Button outline disabled={!isDraft} onClick={onShowImport}>
          <FormattedMessage id="owner.QvestParticipantsTab.import" defaultMessage="Import via Excel" />
        </Button>
        {editButton}
      </ButtonGroup>
    </Actions>
  )
}

const ParticipantsEditor = ({ dispatch, qvest, participants, grouping }) => {
  const { qvestId } = qvest
  const isDraft = isDraftQvestState(qvest.state)
  const isDone = isDoneQvestState(qvest.state)
  const [showCreate, setShowCreate] = useState(false)

  const handleCancel = () => {
    setShowCreate(false)
  }

  const handleToggleSuspend = participant => {
    return dispatch(toggleParticipantSuspension(participant, qvestId))
  }

  const handleEdit = (name, email, participantId) => {
    return dispatch(updateParticipant(qvestId, participantId, { name, email }))
  }

  const handleDownload = () => {
    dispatch(downloadParticipantsFile(qvest.name, participants))
  }

  const handleCreate = (name, email, groupId) => {
    const participant = { name, email }
    if (groupId) {
      participant.groupingConnections = {
        groupingId: grouping.groupingId,
        groupId
      }
    }
    return dispatch(createParticipant(qvestId, participant))
      .then(participant => {
        setShowCreate(false)
        if (isDraft) setShowCreate(true) // start new empty row continuing adding participants
        return participant
      })
  }

  // Modal hook
  const [
    modal,
    showCreateModal,
    showSuspendModal,
    showRemoveModal,
    showRemoveAllModal,
    showImportModal,
    showResendModal,
    showReplyModal
  ] = useModals(dispatch, qvest, participants, grouping, handleCreate, handleCancel)

  const handleStatusClick = (participant, status) => {
    if (status === 'reply') {
      showReplyModal(participant)
    }
  }

  // Table handlers according to qvest state
  let tableHandlers
  if (isDraft) {
    // Draft (swift editing)
    tableHandlers = {
      onRemove: showRemoveModal,
      onEdit: handleEdit,
      onCreate: handleCreate,
      onCancel: handleCancel
    }
  } else if (isDone) {
    // Done (no editing)
    tableHandlers = {
      onStatusClick: handleStatusClick
    }
  } else {
    // Running (rigid editing)
    tableHandlers = {
      onSuspend: showSuspendModal,
      onUndo: handleToggleSuspend,
      onCreate: showCreateModal,
      onResend: showResendModal,
      onCancel: handleCancel,
      onStatusClick: handleStatusClick
    }
  }

  return (
    <Fragment>
      {modal}
      <Card.Body>
        <ActionsBar
          isDraft={isDraft}
          isDone={isDone}
          showCreate={showCreate}
          hasGrouping={grouping != null}
          onRemoveAll={showRemoveAllModal}
          onShowCreate={() => setShowCreate(true)}
          onShowImport={showImportModal}
          onDownload={handleDownload}
          participants={participants}
        />
      </Card.Body>
      <OwnerParticipantTable
        isDraft={isDraft}
        showCreate={showCreate}
        participants={participants}
        {...tableHandlers}
      />
    </Fragment>
  )
}

const OwnerQvestParticipantsTab = ({ participantStore, qvestStore, groupingStore, dispatch, match, intl  }) => {
  const qvestId = match.params.qvestId

  useEffect(() => {
    dispatch(getParticipants(qvestId))
    dispatch(getGroupingByQvestId(qvestId))
  }, [])

  const qvest = filterQvestsById(qvestStore, qvestId)
  const isDraft = isDraftQvestState(qvest.state)
  const rawParticipants = filterParticipantsByQvest(participantStore, qvestId)
  const validity = filterValidityByQvest(participantStore, qvestId)
  const grouping = filterGroupingsByQvest(groupingStore, qvestId)

  if (!rawParticipants && participantStore.isLoading) return <CenteredLoader />

  const participants = sortBy(rawParticipants, p => !hasMailFailed(p) && !hasReplied(p))
  return (
    <Fragment>
      <Helmet>
        <title>{intl.formatMessage(messages.title, { qvestName: qvest && qvest.name })}</title>
      </Helmet>
      <MainLayout>
        <Card>
          <Card.Header>
            <ParticipantsHeading />
            <ButtonGroup>
              <LastUpdateStatus lastUpdate={participantStore.lastUpdate} />
            </ButtonGroup>
          </Card.Header>
          <ErrorMessage validity={validity} participants={participants} error={participantStore.error} />
          <ParticipantsEditor
            dispatch={dispatch}
            qvest={qvest}
            grouping={grouping}
            participants={participants}
          />
        </Card>
        <ParticipantNextBar show={isDraft && participants.length > 1} qvestId={qvestId} />
      </MainLayout>
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    participantStore: state.participants.toJS(),
    groupingStore: state.grouping.toJS(),
    qvestStore: state.qvests.toJS(),
  }
}

export default connect(mapStateToProps)(injectIntl(OwnerQvestParticipantsTab))
