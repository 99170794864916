import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'

import Button from '../../common/Button/Button'
import Input from '../../common/Input/Input'

const errorMessages = defineMessages({
  UNEXPECTED_ERROR: { id: 'qvest.OrderQuestionDataForm.error.UNEXPECTED_ERROR', defaultMessage: 'An unexpected error occurred. We have made a note of the issue and will look into it.' }
})

const WrapperForm = styled.form`
  display: ${({ show }) => show ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-start;

  & > :last-child {
    align-self: flex-end;
  }
`

const FieldSet = styled.fieldset`
  display: block;
  padding: 0;
  border: none;
  width: 100%;
  margin-bottom: 20px;
`

const HugeInput = styled(Input)`
  font-family: ${({ theme }) => theme.font4};
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding: 10px;
`

const Label = styled.label`
  font-family: ${({ theme }) => theme.font2};
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.one[0]};
  margin-bottom: 5px;
`

const ErrorWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  background-color: rgba(248, 105, 92, .2);
  color: #f8695c;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
`

const ErrorText = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-family: ${props => props.theme.font4};
`

const ErrorMessage = injectIntl(({ error, intl }) => {
  if (!error) return null
  return (
    <ErrorWrapper>
      <ErrorText>
        {intl.formatMessage(errorMessages.hasOwnProperty(error) ? errorMessages[error] : errorMessages['UNEXPECTED_ERROR']) }
      </ErrorText>
    </ErrorWrapper>
  )
})

const SubmitButton = ({ disabled, loading }) => {
  if (loading) {
    return (
      <Button disabled size="large">
        <FormattedMessage id="qvest.OrderQuestionDataForm.button.loading" defaultMessage="Loading..." />
      </Button>
    )
  }
  return (
    <Button disabled={disabled} size="large">
      <FormattedMessage id="qvest.OrderQuestionDataForm.button.submit" defaultMessage="Send" />
    </Button>
  )
}

const OrderQuestionDataForm = ({ show, error, loading, onSubmit }) => {
  const [email, setEmail] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(email)
  }

  return (
    <WrapperForm show={show} onSubmit={handleSubmit}>
      <FieldSet>
        <Label htmlFor="email">
          <FormattedMessage id="qvest.OrderQuestionDataForm.label.email" defaultMessage="Enter your email to receive a copy of the data" />:
        </Label>
        <HugeInput
          id="email"
          type="email"
          onChange={({ target }) => setEmail(target.value)}
          value={email}
        />
      </FieldSet>
      <ErrorMessage error={error} />
      <SubmitButton disabled={!email} loading={loading} />
    </WrapperForm>
  )
}

OrderQuestionDataForm.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default OrderQuestionDataForm
