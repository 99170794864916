import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import styled, { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'

import * as authUtils from '../../../actions/utils/authUtils'
import * as theme from '../../../components/theme'
import { loadLocale } from '../../../utils/i18nUtils'
import JoinQvestForm from '../../../components/qvest/JoinQvestForm/JoinQvestForm'
import FindQvestForm from '../../../components/qvest/FindQvestForm/FindQvestForm'
import JamHeader from '../../../components/qvest/JamHeader/JamHeader'
import jamMetaImagePng from '../../../static/img/jam-meta-image2.png'


const ComponentRoot = styled.div`
  padding: 80px 20px;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.two[0]};
`

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`

const PAGE_TITLE = 'Question Jam'
const META_DESCRIPTION = 'Join a Question Jam here. No login required. Question Jam is a free meeting game for facilitators who want to add energy and engagement to any meeting or workshop.'

const JoinPage = ({ match, history }) => {
  const [qvestId, setQvestId] = useState(null)
  const [topic, setTopic] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { pin } = match.params

  const handleSubmit = async (args) => {
    setLoading(true)
    setError(null)
    try {
      const { qvestId, topic } = await authUtils.joinQvest(args.pin, args.name)
      setQvestId(qvestId)
      setTopic(topic)
      if (args.name) {
        // Player joined, redirect to lobby
        history.push(`/qvest/${qvestId}/lobby`)
      } else {
        // Qvest found, redirect to name form
        history.push(`/join/${args.pin}`)
      }
    } catch (ex) {
      if (ex.message === 'INVALID_ACCESS_CODE') {
        history.push('/join') // Clear path from invalid code
      }
      setError(ex.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    // Auto-submit pin, if given in path
    if (pin && !qvestId) {
      handleSubmit({ pin })
    }
  }, [pin])

  const locale = 'en'
  const messages = loadLocale(locale)
  return (
    <IntlProvider locale={locale} messages={messages}>
      <ThemeProvider theme={theme.ocean}>
        <ComponentRoot>
          <Helmet>
            <title>{PAGE_TITLE}</title>
            <meta property="description" content={META_DESCRIPTION} />
            <meta property="og:title" content={PAGE_TITLE} />
            <meta property="og:description" content={META_DESCRIPTION} />
            <meta property="og:image" content={jamMetaImagePng} />
            <meta property="og:image:secure_url" content={jamMetaImagePng} />
          </Helmet>
          <JamHeader topic={topic} />
          <Content>
            <Switch>
              <Route path="/join/:pin">
                <JoinQvestForm
                  show={qvestId != null}
                  loading={loading}
                  error={error}
                  onSubmit={args => handleSubmit({ pin, ...args })}
                />
              </Route>
              <Route path="/join">
                <FindQvestForm
                  loading={loading}
                  error={error}
                  onSubmit={handleSubmit}
                />
              </Route>
            </Switch>
          </Content>
        </ComponentRoot>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default JoinPage
